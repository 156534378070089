import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './library/bootstrap/bootstrap.min.css';
import 'react-slidedown/lib/slidedown.css';
import Theme from './src/theme';
import GlobalTypeStyles from './src/theme/global.style';
import AlgoliaProvider from './src/providers/AlgoliaProvider';
import StoreProvider from './src/providers/StoreProvider';

export const disableCorePrefetching = () => true;

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
    element
}) => {
    return (
        <Theme>
            <GlobalTypeStyles />
            <StoreProvider>
                <AlgoliaProvider>{element}</AlgoliaProvider>
            </StoreProvider>
        </Theme>
    );
};
