import { createContext } from 'react';
import { isMobileLg } from '../utils/helpers';
import { ALGOLIA_INDEX } from '../constants/algolia';

export type AlgoliaContextType<T> = {
    // searching: boolean;
    query: boolean;
    // indexName: string;
    setState?: React.SetStateAction<T>;
};

export const initValuesContext: AlgoliaContextType<AlgoliaContextType<any>> = {
    // searching: isMobileLg,
    query: false
    // indexName: ALGOLIA_INDEX[0]
};

export const AlgoliaContext = createContext<AlgoliaContextType<any>>(initValuesContext);
