import { createContext } from 'react';

export type StoreContextType<T> = {
    cart_quote: Array<Api.CartQuoteItem>;
    setState?: React.SetStateAction<T>;
    handleRemoveQuoteItem: (id: number) => void;
    submitLeadForm: (formId: number, formName: string) => void;
};

export const initValuesContext: StoreContextType<StoreContextType<any>> = {
    cart_quote: [],
    handleRemoveQuoteItem: (id: number) => {},
    submitLeadForm: (formId: number, formName: string = 'Enquiry') => {}
};

export const StoreContext =
    createContext<StoreContextType<any>>(initValuesContext);
