let apiUrl = process.env.GATSBY_API_URL;
if (apiUrl?.endsWith('/')) {
    apiUrl = apiUrl?.slice(0, -1);
}
export const GATSBY_API_URL = apiUrl;
export const GATSBY_API_HOME_URL = process.env.GATSBY_API_LIVE_URL || apiUrl;

export const GATSBY_LIMIT_PER_PAGE_SYNC =
    process.env.GATSBY_LIMIT_PER_PAGE_SYNC;
export const GATSBY_LIMIT_PAGE_SYNC = process.env.GATSBY_LIMIT_PAGE_SYNC;
