import { GatsbyFunctionRequest, GatsbyFunctionResponse } from 'gatsby';
import fetch from 'node-fetch';
import { GATSBY_API_URL } from '../constants/api';
import { validateEmail } from '../utils/helpers';
import { STORE_ID } from '../constants';

interface ContactBody {
    firstname: string;
    lastname: string;
    telephone: string;
    email: string;
}

export default async function handler(req: GatsbyFunctionRequest<ContactBody>, res: GatsbyFunctionResponse) {
    if (req.method === `POST`) {
        const { firstname, lastname, telephone, email, ...body } = req.body;
        if (!firstname || !lastname || !telephone || !email) {
            return res.status(422).json('Field is required');
        }

        if (!validateEmail(email)) {
            return res.status(422).json('Email is required');
        }

        const data = await requestQuote({
            firstname,
            lastname,
            telephone,
            email,
            ...body
        });
        return res.send(data);
    } else {
        return res.status(500).send({ success: false, message: 'Method not found.' });
    }
}

export const requestQuote = async (__data: any) => {
    try {
        const _data = {
            status: 'webforms_submit',
            data: [{ ...__data, store: STORE_ID, store_id: STORE_ID }]
        };

        const { magento_api } = await fetch(`${GATSBY_API_URL}/api/rest/processform`, {
            method: 'POST',
            body: JSON.stringify(_data),
            headers: {
                'Content-type': 'application/json'
            }
        })
            .then((res: any) => res.json())
            .catch((error) => ({}));

        const { data, status } = magento_api || {};
        return {
            success: status,
            message: 'success',
            data
        };
    } catch (error) {
        return {
            success: false,
            message: 'An error occurred. Please try again later.',
            error
        };
    }
};
