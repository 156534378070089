import React, { useState } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { ALGOLIA_APP_ID, ALGOLIA_INDEX, ALGOLIA_SEARCH_KEY } from '../constants/algolia';

import { AlgoliaContext, AlgoliaContextType, initValuesContext } from '../contexts/algolia';

const _algoliasearch = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

const AlgoliaProvider: React.FC<{
    children: React.ReactElement | React.ReactNode;
}> = ({ children }) => {
    const [state, setState] = useState<AlgoliaContextType<any>>(initValuesContext);

    const searchClient = {
        search(requests: any) {
            if (
                requests.every(({ params }: any) => !params.query) ||
                requests.every(({ params }: any) => params.query.trim() === '')
            ) {
                return Promise.resolve({
                    results: requests.map(() => ({
                        hits: [],
                        facets: {},
                        hitsPerPage: 0,
                        nbHits: 0,
                        nbPages: 0,
                        page: 0,
                        processingTimeMS: 0
                    }))
                });
            }

            return _algoliasearch.search(requests);
        }
    };

    return (
        <AlgoliaContext.Provider
            value={{
                ...state,
                setState
            }}
        >
            <InstantSearch
                searchClient={searchClient}
                indexName={ALGOLIA_INDEX[0]}
                onSearchStateChange={({ query }: any) => {
                    if (query && query?.trim() !== '') {
                        if (!state.query) setState((s) => ({ ...s, query: true }));
                    } else if (state.query) setState((s) => ({ ...s, query: false }));
                }}
                stalledSearchDelay={500}
            >
                {children}
            </InstantSearch>
        </AlgoliaContext.Provider>
    );
};
export default AlgoliaProvider;
