import React, { useEffect, useState } from 'react';
import {
    StoreContext,
    StoreContextType,
    initValuesContext
} from '../contexts/store';
import { getQuote, saveQuote } from '../services/quote';

const StoreProvider: React.FC<{ children: React.ReactElement }> = ({
    children
}) => {
    const [state, setState] =
        useState<StoreContextType<any>>(initValuesContext);

    useEffect(() => {
        const cart_quote: Array<Api.CartQuoteItem> = getQuote();
        if (cart_quote.length > 0)
            setState((_state) => ({ ..._state, cart_quote }));
    }, []);

    function submitLeadForm(formId: number, formName: string = 'Enquiry') {
        if (window?.dataLayer) {
            window?.dataLayer?.push({
                event: 'VirtualPageview',
                virtualPageURL: '/web-enquiry-thank-you/' + formId,
                virtualPageTitle: formName + ' - ' + formId
            });
        }
    }

    function handleRemoveQuoteItem(_id: number) {
        const _confirm = confirm(
            'Are you sure you would like to remove this item from the shopping cart?'
        );

        if (_confirm) {
            setState((_state: any) => {
                const _cart_quote = _state.cart_quote.filter(
                    ({ id }: Api.CartQuoteItem) => id !== _id
                );
                saveQuote(_cart_quote);
                return {
                    ..._state,
                    cart_quote: _cart_quote
                };
            });
        }
    }

    return (
        <StoreContext.Provider
            value={{
                ...state,
                handleRemoveQuoteItem,
                submitLeadForm,
                setState
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};
export default StoreProvider;
