const colors = {
    red: '#ba0c2f',
    white: '#ffffff',
    black: '#3F3F3F',
    gray: '#777777',
    bgGray: '#53565a',
    bgBlack: '#121214',
    bgGreen: '#95BE3D',
    border: '#ddd',
    redQE: '#EB340A'
};

export default colors;
